import { Box } from 'components/primitives'
import { FC, ReactNode } from 'react'
import Script from 'next/script'
import Navbar from './navbar'

type Props = {
  children: ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <>
    <Script id="chatbase-config" strategy="afterInteractive">
        {`
          window.chatbaseConfig = {
            chatbotId: "LjPdrRtq_1VCNH2Wnk1q3",
          }
        `}
      </Script>
      <Script
        id="LjPdrRtq_1VCNH2Wnk1q3"
        src="https://www.chatbase.co/embed.min.js"
        strategy="afterInteractive"
        defer
      />
      <Box
        css={{
          background: '$neutralBg',
          height: '100%',
          minHeight: '100vh',
          pt: 80,
        }}
      >
        <Box css={{ mx: 'auto' }}>
          <Navbar />
          <main>{children}</main>
        </Box>
      </Box>
    </>
  )
}

export default Layout
